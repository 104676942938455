<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        color="color_green"
        width="155"
        v-bind="attrs"
        v-on="on"
      >
        New Manual
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Manual label</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-chip
              v-if="alert"
              small
              label
              color="red accent-4"
              style="color:white"
              class="ml-10 mb-n3"
            >
              extension file not allowed, please insert <b>.pdf</b>
            </v-chip>
            <v-file-input
              v-model="manualFile"
              accept=".pdf"
              class="mt-4"
              placeholder="Uploading manual"
              prepend-inner-icon="mdi-file-pdf-box"
              outlined
              truncate-length="50"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="label"
              label="Insert manual label"
              class="mx-10"
            />
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!manualFile"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';

export default {
  components: {
  },
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      alert: false,
      dialog: false,
      overlay: false,
      manualFile: null,
      label: '',
      newManual: {}
    };
  },
  computed: {
    ...mapState(['file_transfer'])
  },
  mounted() {},
  methods: {
    ...mapActions(['setUpload', 'createCustomerManualsAction']),
    async save() {
      if (this.manualFile.type == 'application/pdf') {
        this.alert = false;
        this.overlay = true;
        const params = {
          Bucket: S3_BUCKETS.customer(),
          Key: `${this.$store.state.customerCRMID.crm_id}/manuals/${this.manualFile.name}`,
          Body: this.manualFile
        };
        await this.setUpload(params);

        if (this.file_transfer.uploadState) {
          this.newManual = {
            label_code: `${this.$store.state.customerCRMID.crm_id}_${this.manualFile.name.replace('.pdf', '')}.manual`,
            label_value: this.label,
            manual_s3_path: `${this.$store.state.customerCRMID.crm_id}/manuals/${this.manualFile.name}`,
            customer_id: this.$store.state.customerCRMID.crm_id
          };
          await this.createCustomerManualsAction(this.newManual);
        }

        this.manualFile = null;
        this.newManual = {};
        this.label = '';
        this.dialog = false;
        this.overlay = false;
      } else {
        this.alert = true;
      }
    },
    close() {
      this.alert = false;
      this.manualFile = null;
      this.newManual = {};
      this.label = '';
      this.dialog = false;
    }
  }
};
</script>
